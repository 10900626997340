import React, { useState } from "react";
import { Header } from "../components/itax-ui";
import { RiMenu2Line } from "react-icons/ri";

export default function Trade() {
    const [isSideNavOpen, setIsSideNavOpen] = useState(true);

    const toggleSideNav = () => {
        setIsSideNavOpen(!isSideNavOpen);
    }

    return (
        <>
            {isSideNavOpen && <Header active={isSideNavOpen === true ? "true" : "false"} />}
            <div className={isSideNavOpen ? "fixed top-0 right-0 w-full md:w-[79%] lg:w-[79%] xl:w-[79%] h-screen overflow-y-auto" : "fixed top-0 right-0 w-full h-screen overflow-y-auto"}>
                <div className="flex flex-col w-full gap-12 p-6 bg-white md:p-10 lg:p-12 xl:p-12 rounded-2xl dark:bg-transparent">
                    <div className="flex flex-col gap-3 p-6 mt-4 border dark:border-[#656565] rounded-2xl bg-gradient-to-b from-gray-100/75 to-white/10 dark:from-gray-900/25 dark:to-[#010101] backdrop-blur">
                        <button
                            className="flex items-center self-end justify-end p-4 text-black rounded-lg dark:text-white bg-gradient-to-b from-gray-100/75 to-white/10 dark:from-gray-700/75 dark:to-gray-900/75 backdrop-blur flex-end"
                            onClick={toggleSideNav}
                        >
                            <RiMenu2Line />
                        </button>
                        <div className="grid grid-cols-1 gap-2 md:gap-3 lg:gap-6 xl::gap-6 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
                            <h1 className="text-lg font-semibold text-black dark:text-white">
                                Swap Widget
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}