import React, { useState } from "react";
import { Header } from "../components/itax-ui";
import { RiCalendar2Line, RiMenu2Line, RiShoppingCart2Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";

export default function Home() {
    const [isSideNavOpen, setIsSideNavOpen] = useState(true);

    const toggleSideNav = () => {
        setIsSideNavOpen(!isSideNavOpen);
    }

    return (
        <>
            {isSideNavOpen && <Header active={isSideNavOpen === true ? "true" : "false"} />}
            <div className={isSideNavOpen ? "fixed top-0 right-0 w-full md:w-9/12 lg:w-[79%] xl:w-[79%] h-screen overflow-y-auto" : "fixed top-0 right-0 w-full h-screen overflow-y-auto"}>
                <div className="flex flex-col w-full gap-12 p-6 bg-white md:p-10 lg:p-12 xl:p-12 rounded-2xl dark:bg-transparent">
                    <div className="flex self-end justify-end w-full gap-5 flex-end">
                        <NavLink to="/dashboard" className="flex items-center gap-2 px-1 py-1 text-gray-900 bg-white border border-[#656565] rounded-lg dark:border-[#656565] dark:text-white dark:bg-transparent text-start md:px-4 md:py-2 lg:px-4 lg:py-2.5">
                            <RiShoppingCart2Line />
                            Buy ITAXeth
                        </NavLink>
                        <button
                            className="flex items-center self-end justify-end p-4 text-black rounded-lg dark:text-white bg-gradient-to-b from-gray-100/75 to-white/10 dark:from-gray-700/75 dark:to-gray-900/75 backdrop-blur flex-end"
                            onClick={toggleSideNav}
                        >
                            <RiMenu2Line />
                        </button>
                    </div>
                    <div className="grid grid-cols-1 gap-2 md:gap-3 lg:gap-6 xl:gap-6 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
                        <div>
                            <div className="flex flex-col items-center text-center justify-center gap-3 p-6 mt-4 border dark:border-[#656565] rounded-2xl bg-gradient-to-b from-gray-100/75 to-white/10 dark:from-gray-900/25 dark:to-[#010101] backdrop-blur">
                                <h1 className="mb-3 text-2xl text-black dark:text-white">
                                    Active Network
                                </h1>
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center w-10 h-10 p-1 bg-white rounded-full">
                                        <img src="/images/network/bsc.svg" className="w-6 h-6" alt="" />
                                    </div>
                                    <h1 className="font-semibold text-black dark:text-white">Binance Smart Chain</h1>
                                </div>
                                <img src="/images/pattern/vector.png" className="absolute right-0 top-[1px] rounded-tr-xl" alt="" />
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col items-center text-center justify-center gap-3 p-6 mt-4 border dark:border-[#656565] rounded-2xl bg-gradient-to-b from-gray-100/75 to-white/10 dark:from-gray-900/25 dark:to-[#010101] backdrop-blur">
                                <h1 className="mb-3 text-2xl text-black dark:text-white">
                                    Wallet Holders
                                </h1>
                                <div className="flex items-center gap-4">
                                    <h1 className="text-xl text-black dark:text-white">850 400</h1>
                                </div>
                                <img src="/images/pattern/vector.png" className="absolute right-0 top-[1px] rounded-tr-xl" alt="" />
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col items-center text-center justify-center gap-3 p-6 mt-4 border dark:border-[#656565] rounded-2xl bg-gradient-to-b from-gray-100/75 to-white/10 dark:from-gray-900/25 dark:to-[#010101] backdrop-blur">
                                <h1 className="mb-3 text-2xl text-black dark:text-white">
                                    Value Locked
                                </h1>
                                <div className="flex items-center gap-4">
                                    <h1 className="text-xl text-black dark:text-white">$850.9 M</h1>
                                </div>
                                <img src="/images/pattern/vector.png" className="absolute right-0 top-[1px] rounded-tr-xl" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
                        <div className="relative flex items-center justify-center w-full">
                            <div id="card-layer" className="z-50 flex flex-col justify-center w-full h-full p-8 text-gray-600 border shadow-xl dark:border-[#656565] rounded-2xl card-border bg-gray-100/75 dark:bg-[#010101] backdrop-blur">
                                <div className="flex flex-col items-center justify-center w-full gap-1">
                                    <p className="mb-4 text-2xl tracking-widest text-blue-800 dark:text-white">Price</p>
                                    <div className="tracking-widest">
                                        <div className="flex items-center gap-2">
                                            <h2 className="text-xl text-center text-blue-800 dark:text-white">$41 780 160</h2>
                                            <div className="text-green-500">+6.22 %</div>
                                        </div>
                                    </div>
                                    <img src="/images/pattern/vector.png" className="absolute right-0 top-[1px] rounded-tr-xl" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="relative flex items-center justify-center">
                            <div id="card-layer" className="z-50 flex flex-col justify-start w-full h-full p-8 text-gray-600 border shadow-xl dark:border-[#656565] rounded-2xl card-border bg-gray-100/75 dark:bg-[#010101] backdrop-blur">
                                <div className="flex flex-col items-start justify-center w-full gap-1">
                                    <div className="flex items-center gap-2 px-3 py-1 bg-transparent border border-[#656565] rounded-lg backdrop-blur">
                                        <div className="p-1 text-xs text-black dark:text-white">
                                            W
                                        </div>
                                        <div>|</div>
                                        <div className="p-1 text-xs text-black dark:text-gray-500">
                                            M
                                        </div>
                                    </div>
                                    <div className="flex justify-center w-full mt-2">
                                        <p className="mb-3 text-2xl tracking-widest text-blue-800 dark:text-white">Volume</p>
                                    </div>
                                    <div className="w-full mb-5 tracking-widest">
                                        <div className="flex items-center justify-center w-full gap-2 text-center">
                                            <h2 className="text-xl text-center text-blue-800 dark:text-white">$3 150 950</h2>
                                            <div className="text-red-500">-5.38 %</div>
                                        </div>
                                    </div>
                                    <img src="/images/pattern/vector.png" className="absolute right-0 top-[1px] rounded-tr-xl" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-6">
                        <div className="relative flex items-center justify-center">
                            <div id="card-layer" className="z-50 flex flex-col justify-start w-full h-full p-8 text-gray-600 border shadow-xl dark:border-[#656565] rounded-2xl card-border bg-gray-100/75 dark:bg-[#010101] backdrop-blur">
                                <div className="flex flex-col items-center justify-center w-full gap-1">
                                    <p className="mt-4 mb-12 text-lg tracking-widest text-blue-800 md:mt-8 lg:mt-8 xl:mt-8 md:text-2xl lg:text-3xl xl:text-3xl dark:text-white">Current Staking Pool</p>
                                    <div className="w-full mb-4 tracking-widest md:mb-10 lg:mb-12 xl:mb-12">
                                        <div className="flex flex-row items-center justify-between w-full gap-2 md:flex-row lg:flex-row xl:flex-row">
                                            <div className="flex flex-col items-center w-full gap-3 md:flex-col lg:flex-col xl:flex-col">
                                                <h2 className="mb-2 text-xl font-semibold text-blue-800 md:text-2xl lg:text-3xl xl:text-3xl dark:text-white">Days</h2>
                                                <div className="text-white text-md md:text-lg lg:text-2xl xl:text-2xl">30</div>
                                            </div>
                                            <div className="flex flex-col items-center w-full gap-3 md:flex-col lg:flex-col xl:flex-col">
                                                <h2 className="mb-2 text-xl font-semibold text-blue-800 md:text-2xl lg:text-3xl xl:text-3xl dark:text-white">APY</h2>
                                                <div className="text-white text-md md:text-lg lg:text-2xl xl:text-2xl">32%</div>
                                            </div>
                                            <div className="flex flex-col items-center w-full gap-3 md:flex-col lg:flex-col xl:flex-col">
                                                <h2 className="mb-2 text-xl font-semibold text-blue-800 md:text-2xl lg:text-3xl xl:text-3xl dark:text-white">Left</h2>
                                                <div className="text-white text-md md:text-lg lg:text-2xl xl:text-2xl">9</div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src="/images/pattern/vector.png" className="absolute right-0 top-[1px] rounded-tr-xl" alt="" />
                                </div>
                            </div>
                            <div className="absolute w-12 h-12 rounded-full shadow-2xl shadow-gray-900 top-4 left-12 bg-gradient-to-b from-gray-800 to-gray-900 backdrop-blur blur-2xl"></div>
                        </div>
                    </div>
                </div>

                <img src="/images/pattern/cube.png" className="absolute right-0 bottom-1" alt="" />
            </div>
        </>
    )
}