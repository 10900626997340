import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Investment from "./pages/Investment";
import Store from "./Store";
import Home from "./pages/Home";
import Trade from "./pages/Trade";

export default function Navigation() {
    return (
        <Store>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/stake"} element={<Investment />} />
                    <Route path={"/trade"} element={<Trade />} />
                </Routes>
            </BrowserRouter>
        </Store>
    )
}