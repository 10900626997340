import React, { useContext, useState, useEffect } from 'react'
import { Header } from '../components/itax-ui'
import { Context } from "../Store";
import { RiMenu2Line } from 'react-icons/ri';
import { getAppByChainId } from '../libs/Env';
import { amountFormat, checkIsNeedApprove, getTokenBalance } from '../libs/WebooLib';
import BigNumber from 'bignumber.js';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import axios from "axios";

export default function Investment() {
    const [isSideNavOpen, setIsSideNavOpen] = useState(true);
    const [state, dispatch] = useContext(Context);
    const [balanceToken, setBalanceToken] = useState(0)
    const [totalStakedBalance, setTotalStakedBalance] = useState(0)
    const [stakedBalance, setStakedBalance] = useState(0)
    const [amountStake, setAmountStake] = useState(0)
    const [amountUnstake, setAmountUnstake] = useState(0)
    const [isInvalidBalance, setIsInvalidBalance] = useState(false)
    const [isNeedApprove, setIsNeedApprove] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [estimateTax, setEstimateTax] = useState(0)
    const [estimateReward, setEstimateReward] = useState(0)
    const [isInvalidBalanceUnstake, setIsInvalidBalanceUnstake] = useState(false)
    const [totalDaysStaking, setTotalDaysStaking] = useState(0)
    const [totalEarnedETH, setTotalEarnedETH] = useState(0)
    const [shillURL, setShillURL] = useState('')
    const [nextDistributionBlock, setNextDistributionBlock] = useState(0)
    const [alert, setAlert] = useState(false)

    const toggleSideNav = () => {
        setIsSideNavOpen(!isSideNavOpen);
    }

    useEffect(async () => {
        if (state.web3 && state.account) {


            await initiate();
        }
    }, [state])

    const initiate = async () => {
        const _balance = await getTokenBalance(state, state.account, getAppByChainId(state.chainId).TOKEN_ADDRESS)
        setBalanceToken(_balance)

        const _balanceStaked = await getTokenBalance(state, getAppByChainId(state.chainId).STAKING_ADDRESS, getAppByChainId(state.chainId).TOKEN_ADDRESS)
        setTotalStakedBalance(_balanceStaked)

        const _balanceUserStaked = await getTokenBalance(state, state.account, getAppByChainId(state.chainId).STAKING_ADDRESS)
        setStakedBalance(_balanceUserStaked)
        const _isNeedApprove = await checkIsNeedApprove(
            state,
            state.account,
            getAppByChainId(state.chainId).TOKEN_ADDRESS,
            getAppByChainId(state.chainId).STAKING_ADDRESS
        )
        setIsNeedApprove(_isNeedApprove)

        const stakeContract = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).STAKING_ABI,
            getAppByChainId(state.chainId).STAKING_ADDRESS
        );
        const _estimateTax = await stakeContract.methods.estimateTaxPercent(
            state.web3.utils.toHex(state.account)
        ).call()
        setEstimateTax(_estimateTax)

        const _estimateReward = await stakeContract.methods.dividendOf(
            state.web3.utils.toHex(state.account)
        ).call()
        setEstimateReward(state.web3.utils.fromWei(_estimateReward))

        const _shares = await stakeContract.methods.shares(
            state.web3.utils.toHex(state.account)
        ).call();
        if (_shares['lastDepositTimestamp'] > 0) {
            const nowTimestamp = Date.now();
            const differenceInMilliseconds = nowTimestamp - (_shares['lastDepositTimestamp'] * 1000);
            const differenceInDays = Math.floor(differenceInMilliseconds / (24 * 60 * 60 * 1000));
            setTotalDaysStaking(differenceInDays + 1)
        }
        if (_shares['totalClaimed']) {
            setTotalEarnedETH(state.web3.utils.fromWei(_shares['totalClaimed']))
        }
        const _nextDistributionBlock = await stakeContract.methods.nextDistributionBlock().call();
        setNextDistributionBlock(_nextDistributionBlock)
    }

    const handleChangeAmountStake = (e) => {
        const _amount = new BigNumber(e.target.value)

        if (_amount.isGreaterThan(balanceToken)) {
            setIsInvalidBalance(true)
            setAmountStake(balanceToken.toString())
            return
        } else {
            setIsInvalidBalance(false)
            setAmountStake(e.target.value)
        }
    }

    const handleChangeAmountUnstake = (e) => {
        const _amount = new BigNumber(e.target.value)

        if (_amount.isGreaterThan(stakedBalance)) {
            setIsInvalidBalanceUnstake(true)
            setAmountUnstake(balanceToken.toString())
            return
        } else {
            setIsInvalidBalanceUnstake(false)
            setAmountUnstake(e.target.value)
        }
    }

    const handleSubmitStake = async () => {
        if (!amountStake) {
            toast.error('Please input your amount')
            return;
        }
        Swal.fire({
            title: 'Confirmation',
            text: `You will stake ${amountStake} ITAX ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, please!',
            cancelButtonText: 'Ahh, wait...',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                const stakeContract = await new state.web3.eth.Contract(
                    getAppByChainId(state.chainId).STAKING_ABI,
                    getAppByChainId(state.chainId).STAKING_ADDRESS
                );
                await stakeContract.methods.stake(
                    state.web3.utils.toWei(amountStake)
                ).send({ from: state.account })
                    .then(async (receipt) => {
                        toast.success('Submit Stake success')
                        setIsLoading(false)
                        await initiate();
                    }).catch((err) => {
                        toast.error(err.message)
                        setIsLoading(false)
                    })
            }
        })
    }

    const handleSubmitUnstake = async () => {
        if (!amountUnstake) {
            toast.error('Please input your amount')
            return;
        }
        Swal.fire({
            title: 'Confirmation',
            text: `You will unstake ${amountUnstake} ITAXStake ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, please!',
            cancelButtonText: 'Ahh, wait...',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                const stakeContract = await new state.web3.eth.Contract(
                    getAppByChainId(state.chainId).STAKING_ABI,
                    getAppByChainId(state.chainId).STAKING_ADDRESS
                );
                await stakeContract.methods.unstake(
                    state.web3.utils.toWei(amountUnstake)
                ).send({ from: state.account })
                    .then(async (receipt) => {
                        toast.success('Submit Unstake success')
                        setIsLoading(false)
                        await initiate();
                    }).catch((err) => {
                        toast.error(err.message)
                        setIsLoading(false)
                    })
            }
        })
    }

    const handleApprove = async () => {
        const token = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).ERC20_ABI,
            getAppByChainId(state.chainId).TOKEN_ADDRESS
        );
        setIsLoading(true)
        await token.methods.approve(
            getAppByChainId(state.chainId).STAKING_ADDRESS,
            state.web3.utils.toWei(balanceToken.multipliedBy(2).toString())
        ).send({ from: state.account })
            .then(async (receipt) => {
                toast.success('Approve success')
                setIsLoading(false)
                await initiate();
            }).catch((err) => {
                toast.error(err.message)
                setIsLoading(false)
            })
    }

    const handleShill = async () => {
        if (shillURL === '') {
            toast.error('Please input your shill link')
            return;
        }
        Swal.fire({
            title: 'Confirmation',
            text: `You will submit your shill link ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, please!',
            cancelButtonText: 'Ahh, wait...',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    const stakeContract = await new state.web3.eth.Contract(
                        getAppByChainId(state.chainId).STAKING_ABI,
                        getAppByChainId(state.chainId).STAKING_ADDRESS
                    );
                    const epoch = await stakeContract.methods.currentEpoch().call();
                    await axios.post(getAppByChainId(state.chainId).API_URL + '/api/shill', {
                        address: state.account,
                        epoch: epoch,
                        url: shillURL,
                    })
                    toast.success('Submit Shill success')
                    setAlert(true);
                    await initiate();
                } catch (error) {
                    if (error.response && error.response.status === 422 && error.response.data.errors) {
                        // Loop through each validation error
                        for (let field in error.response.data.errors) {
                            // Show each error message in a toast
                            error.response.data.errors[field].forEach(errorMessage => {
                                toast.error(errorMessage);
                            });
                        }
                    } else {
                        // If it's not a 422 error or the format is unexpected, show a generic error message
                        toast.error(error.message);
                    }
                    console.log(error);
                } finally {
                    setIsLoading(false)
                }
            }
        });
    }

    const handleClaimReward = async () => {
        setIsLoading(true)
        const { data } = await axios.post(getAppByChainId(state.chainId).API_URL + '/api/web3/check-gas', {
            address: state.account,
            stakeAddress: getAppByChainId(state.chainId).STAKING_ADDRESS,
        });

        Swal.fire({
            title: 'Confirmation',
            text: `You need spend for claim gas ${data.estimatedGasInEth} ETH`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, please!',
            cancelButtonText: 'Ahh, wait...',
        }).then(async (result) => {

            if (result.isConfirmed) {

                try {
                    const txHash = await state.web3.eth.sendTransaction({
                        from: state.web3.utils.toHex(state.account),
                        to: state.web3.utils.toHex(data.ownerAddress),
                        value: `${data.estimatedGasInWei}`
                    });
                    // console.log('Transaction Hash: ', txHash.transactionHash);
                    const payloadClaim = {
                        address: state.account,
                        stakeAddress: getAppByChainId(state.chainId).STAKING_ADDRESS,
                        gasHash: txHash.transactionHash,
                    }
                    const claimData = await axios.post(getAppByChainId(state.chainId).API_URL + '/api/web3/claim-reward', payloadClaim);
                    if (claimData.data.status === 200) {
                        toast.success('Claim Reward success')
                        await initiate();
                    }
                } catch (error) {
                    toast.error(error.message)
                } finally {
                    setIsLoading(false)
                }
            } else {
                setIsLoading(false)
            }
        })
    }


    return (
        <>
            {isSideNavOpen && <Header active={isSideNavOpen === true ? "true" : "false"} />}
            <div
                className={isSideNavOpen ? "fixed top-0 right-0 w-full md:w-9/12 lg:w-[79%] xl:w-[79%] h-screen overflow-y-scroll" : "fixed top-0 right-0 w-full h-screen overflow-y-scroll"}>
                <div className='p-6 md:p-10 lg:p-12 xl:p-12'>
                    <div className="flex self-end justify-end w-full gap-5 flex-end">
                        <button
                            className="flex items-center self-end justify-end p-4 text-black rounded-lg dark:text-white bg-gradient-to-b from-gray-100/75 to-white/10 dark:from-gray-700/75 dark:to-gray-900/75 backdrop-blur flex-end"
                            onClick={toggleSideNav}
                        >
                            <RiMenu2Line />
                        </button>
                    </div>
                    <div className="grid w-full grid-cols-1 gap-4 mt-8 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
                        <div className="relative flex items-start justify-start">
                            <div id="card-layer"
                                className="z-50 flex flex-col justify-start w-full h-full p-8 text-gray-600 border shadow-xl dark:border-[#656565] rounded-2xl card-border bg-gray-100/75 dark:bg-[#010101] backdrop-blur">
                                <div className='flex flex-col items-start justify-start w-full gap-6 text-left'>
                                    <div className='relative w-full'>
                                        <label htmlFor="" className='text-black dark:text-white'>Total Pool
                                            Staked</label><br />
                                        <div className="flex items-center gap-3 mt-3">
                                            <input type="text" placeholder='0' disabled={true}
                                                value={amountFormat(totalStakedBalance.toString(), 0)}
                                                className='w-full px-4 py-2 text-center text-black bg-white border rounded-md dark:bg-transparent dark:border-gray-800 dark:text-white' />
                                            <button type='button' disabled={true} className='px-3 py-2 text-white rounded-lg bg-gradient-to-tr from-zinc-900 via-zinc-950 to-yellow-700'>
                                                ITAX
                                            </button>
                                        </div>
                                    </div>

                                    <div className='w-full'>
                                        <label htmlFor="" className='text-black dark:text-white'>Eligible
                                            Tokens</label><br />
                                        <div className="flex items-center gap-3 mt-3">
                                            <input type="text" disabled={true} value={amountFormat(balanceToken.toString(), 0)}
                                                placeholder='0'
                                                className='w-full px-4 py-2 text-center text-black bg-white border rounded-md dark:bg-transparent dark:border-gray-800 dark:text-white' />
                                            <button type='button' disabled={true} className='px-3 py-2 text-white rounded-lg bg-gradient-to-tr from-zinc-900 via-zinc-950 to-yellow-700'>
                                                ITAX
                                            </button>
                                        </div>
                                    </div>

                                    <div className='w-full'>
                                        <label htmlFor="" className='text-black dark:text-white'>Your Staked
                                            Token</label><br />
                                        <div className="flex items-center gap-3 mt-3">
                                            <input type="text" value={amountFormat(stakedBalance.toString(), 0)} disabled={true}
                                                placeholder='0'
                                                className='w-full px-4 py-2 text-center text-black bg-white border rounded-md dark:bg-transparent dark:border-gray-800 dark:text-white' />
                                            <button type='button' disabled={true} className='px-3 py-2 text-white rounded-lg bg-gradient-to-tr from-zinc-900 via-zinc-950 to-yellow-700'>
                                                ITAX
                                            </button>
                                        </div>
                                    </div>

                                    <div className='w-full'>
                                        <label htmlFor="" className='text-black dark:text-white'>Stake Token</label><br />
                                        <div className="flex items-center gap-2 mt-3">
                                            <input type="text" placeholder="Input your amount"
                                                onChange={(e) => handleChangeAmountStake(e)}
                                                className={"w-full px-4 py-2 text-center text-black bg-white rounded-md dark:bg-transparent border dark:border-gray-800 dark:text-white" + (isInvalidBalance ? ' border border-red-500' : '')}
                                            />
                                            {isNeedApprove && (
                                                <button
                                                    disabled={isInvalidBalance}
                                                    type='button'
                                                    className='px-3 py-2 text-white rounded-md bg-gradient-to-tr from-zinc-950 via-yellow-700 to-orange-300'
                                                    onClick={() => handleApprove()}
                                                >
                                                    {isLoading && (
                                                        <div className='flex flex-row justify-center gap-2 align-center'>
                                                            <FaSpinner className='animate-spin' /> Loading </div>
                                                    )}
                                                    {!isLoading && (
                                                        <>Approve</>
                                                    )}
                                                </button>
                                            )}
                                            {!isNeedApprove && (
                                                <button
                                                    disabled={isInvalidBalance}
                                                    type='button'
                                                    className='px-3 py-2 text-white rounded-md bg-gradient-to-tr from-zinc-950 via-yellow-700 to-orange-300'
                                                    onClick={() => handleSubmitStake()}
                                                >
                                                    {isLoading && (
                                                        <div className='flex flex-row justify-center gap-2 align-center'>
                                                            <FaSpinner className='animate-spin' /> Loading </div>
                                                    )}
                                                    {!isLoading && (
                                                        <>Stake</>
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                        {isInvalidBalance && <div className='text-red-500'>Your balance is not enough</div>}
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="" className='text-black dark:text-white'>Enter Twitter
                                            Link</label><br />
                                        <div className="flex items-center gap-2 mt-3">
                                            <input type="text" placeholder="Enter your shared link"
                                                onChange={(e) => setShillURL(e.target.value)}
                                                className='w-full px-4 py-2 text-center text-black bg-white border rounded-md dark:bg-transparent dark:border-gray-800 dark:text-white' />

                                            {isNeedApprove && (
                                                <button
                                                    disabled={isInvalidBalance}
                                                    type='button'
                                                    className='px-3 py-2 text-white rounded-md bg-gradient-to-tr from-zinc-950 via-yellow-700 to-orange-300'
                                                >
                                                    <>Approve</>
                                                </button>
                                            )}
                                            {!isNeedApprove && (
                                                <button
                                                    disabled={isInvalidBalance}
                                                    type='button'
                                                    className='px-3 py-2 text-white bg-gray-500 rounded-md'
                                                    onClick={() => handleShill()}
                                                >
                                                    {isLoading && (
                                                        <div className='flex flex-row justify-center gap-2 align-center'>
                                                            <FaSpinner className='animate-spin' /> Loading </div>
                                                    )}
                                                    {!isLoading && (
                                                        <>Shill</>
                                                    )}
                                                </button>
                                            )}

                                        </div>
                                    </div>

                                    {alert === true ? (
                                        <div className='py-4 font-semibold text-green-900 bg-green-300 rounded-lg'>
                                            Thank you for sharing our community project you are eligible for the next
                                            distribution!
                                        </div>
                                    ) : (
                                        <div className='py-4 font-semibold text-red-900 bg-red-300 rounded-lg dark:text-red-800 dark:bg-transparent'>
                                            Post on twitter about the project, paste the link and click shill to be eligible for
                                            the next distribution!
                                        </div>
                                    )}
                                    <img src="/images/pattern/vector.png" className="absolute -z-0 right-0 top-[1px] rounded-tr-xl" alt="" />
                                </div>
                            </div>
                            <div
                                className="absolute w-3 h-3 rounded-full shadow-2xl shadow-gray-400 bottom-2 right-36 bg-gradient-to-b from-white to-gray-200"></div>
                            <div
                                className="absolute w-20 h-20 rounded-full shadow-2xl shadow-gray-400 bottom-2 right-2 bg-gradient-to-b from-gray-400 to-gray-200 backdrop-blur blur-3xl"></div>
                            <div
                                className="absolute w-20 h-20 rounded-full shadow-2xl shadow-gray-400 top-2 right-2 bg-gradient-to-b from-gray-400 to-gray-200 backdrop-blur blur-3xl"></div>
                        </div>
                        <div className="relative flex items-center justify-center">
                            <div id="card-layer"
                                className="z-50 flex flex-col justify-start w-full h-full p-8 text-gray-600 border shadow-xl dark:border-[#656565] rounded-2xl card-border bg-gray-100/75 dark:bg-[#010101] backdrop-blur">
                                <div className='flex flex-col items-start justify-start gap-6 text-start'>
                                    <div className='w-full'>
                                        <label htmlFor="" className='text-black dark:text-white'>Unstake Token</label><br />
                                        <div className="flex items-center gap-2 mt-3">
                                            <input
                                                type="text"
                                                placeholder='0'
                                                className='w-full px-4 py-2 text-center text-black bg-white border rounded-md dark:bg-transparent dark:border-gray-800 dark:text-white'
                                                onChange={(e) => handleChangeAmountUnstake(e)}
                                            />
                                            <button
                                                onClick={() => handleSubmitUnstake()}
                                                className='px-3 py-2 text-white rounded-md bg-gradient-to-tr from-zinc-950 via-yellow-700 to-orange-300'
                                            >
                                                {isLoading && (
                                                    <div className='flex flex-row justify-center gap-2 align-center'>
                                                        <FaSpinner className='animate-spin' /> Loading </div>
                                                )}
                                                {!isLoading && (
                                                    <>Unstake</>
                                                )}
                                            </button>
                                        </div>
                                        {isInvalidBalanceUnstake &&
                                            <div className='text-red-500'>Your balance is not enough</div>}
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="" className='text-black dark:text-white'>Your Reward</label><br />
                                        <div className="flex items-center gap-2 mt-3">
                                            <input type="text" placeholder="0" value={estimateReward} disabled={true}
                                                className='w-full px-4 py-2 text-center text-black bg-white border rounded-md dark:bg-transparent dark:border-gray-800 dark:text-white' />
                                            <button
                                                onClick={() => handleClaimReward()}
                                                className='px-3 py-2 text-white rounded-md bg-gradient-to-tr from-zinc-950 via-yellow-700 to-orange-300 whitespace-nowrap'
                                            >
                                                {isLoading && (
                                                    <div className='flex flex-row justify-center gap-2 align-center'>
                                                        <FaSpinner className='animate-spin' /> Loading </div>
                                                )}
                                                {!isLoading && (
                                                    <>Claim Reward</>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="" className='text-black dark:text-white'>Token Unstake
                                            Tax</label><br />
                                        <div className="flex items-center gap-2 mt-3">
                                            <input type="text" disabled={true} value={estimateTax + "%"} placeholder=''
                                                className='w-full px-4 py-2 text-center text-black bg-white border rounded-md dark:bg-transparent dark:border-gray-800 dark:text-white' />
                                            <button
                                                className='px-3 py-2 text-white rounded-md bg-gradient-to-tr from-zinc-950 via-yellow-700 to-orange-300 whitespace-nowrap'>Day {totalDaysStaking}</button>
                                        </div>
                                    </div>
                                    <div
                                        className='flex flex-col items-center justify-between w-full gap-4 md:flex-row lg:flex-row'>
                                        <div className='w-full'>
                                            <label htmlFor="" className='text-black dark:text-white'>Total Earned
                                                ETH</label><br />
                                            <input type="text" placeholder='' value={amountFormat(totalEarnedETH)}
                                                className='w-full px-4 py-2 mt-3 text-center text-black bg-white border rounded-md dark:bg-transparent dark:border-gray-800 dark:text-white' />
                                        </div>
                                        <div className='w-full'>
                                            <label htmlFor="" className='text-black dark:text-white'>Actual Share of
                                                Pool</label><br />
                                            <input type="text" placeholder='0%'
                                                className='w-full px-4 py-2 mt-3 text-center text-black bg-white border rounded-md dark:bg-transparent dark:border-gray-800 dark:text-white' />
                                        </div>
                                    </div>

                                    <div className='flex flex-col justify-center w-full'>
                                        <center>
                                            <div className='text-black dark:text-white'>Next
                                                Distribution</div>
                                            <div
                                                className='px-4 py-2 mt-3 text-black bg-white border rounded-md w-max dark:bg-transparent dark:border-gray-800 dark:text-white'>
                                                {amountFormat(nextDistributionBlock, 0)} Blocks
                                            </div>
                                        </center>
                                    </div>
                                </div>
                                <img src="/images/pattern/vector.png" className="absolute right-0 -z-0 top-[1px] rounded-tr-xl" alt="" />
                            </div>
                            <div
                                className="absolute w-3 h-3 rounded-full shadow-2xl shadow-gray-400 bottom-2 right-36 bg-gradient-to-b from-white to-gray-200"></div>
                            <div
                                className="absolute w-20 h-20 rounded-full shadow-2xl shadow-gray-400 bottom-2 right-2 bg-gradient-to-b from-gray-400 to-gray-200 backdrop-blur blur-3xl"></div>
                        </div>

                        <img src="/images/pattern/cube.png" className="absolute right-0 bottom-20" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}
