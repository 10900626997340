import './App.css';
import Navigation from "./navigation";
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { ModalWeb3 } from "./components/itax-ui";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function getLibrary(provider) {
    return new Web3Provider(provider)
}

const Web3ReactProviderReloaded = createWeb3ReactRoot('anotherOne')


function App() {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ReactProviderReloaded getLibrary={getLibrary}>
                <div className="relative flex h-screen bg-white bg-cover App dark:bg-hero-shape">
                    <Navigation />
                    <ToastContainer position='top-center' autoClose={10000} />
                </div>
            </Web3ReactProviderReloaded>
        </Web3ReactProvider>
    );
}

export default App;
